import revive_payload_client_6aGNVtM4gs9NDxuAFwYVJdxP_RhPI6pfChxtFhrJGgM from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qu3YYvrFVJGVYwTY4_I05ztCPyP0bRDiuO0TM6FaKSQ from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_k2CV_JjYuZU7aPBT8YtakdXBsyQQGn5c5YaSNCNZePE from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ga7Y2KlPl1x6Qt2eJNbq8nO5p6J4PQSo9TJpCxNnYRA from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Kue7TZgaI5qTcrI7k2m6XYfGZXn_AIkgsTHtP3xgpeQ from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_D0K_y1klXUMjOg5EPzUJ7ZD89zZ1ZM6baH6Qsg_dhIg from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_zHs6_XZgPq6gw5kdcHkpxB9ot_4wuPAVFOpKj24zDsQ from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Xw1G7sNmSLZU_fMeg1F6id0ypmpZP2u_HbpWX79ZAUA from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/workspace/kody/hi3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_6WcLI_RKBNoI5X_pVx1hF9_w_bj_U9JHiRNM_nu3S0Q from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._b1deee5a42ba1d9001ebe91695b87aed/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_6aGNVtM4gs9NDxuAFwYVJdxP_RhPI6pfChxtFhrJGgM,
  unhead_qu3YYvrFVJGVYwTY4_I05ztCPyP0bRDiuO0TM6FaKSQ,
  router_k2CV_JjYuZU7aPBT8YtakdXBsyQQGn5c5YaSNCNZePE,
  payload_client_ga7Y2KlPl1x6Qt2eJNbq8nO5p6J4PQSo9TJpCxNnYRA,
  navigation_repaint_client_Kue7TZgaI5qTcrI7k2m6XYfGZXn_AIkgsTHtP3xgpeQ,
  check_outdated_build_client_D0K_y1klXUMjOg5EPzUJ7ZD89zZ1ZM6baH6Qsg_dhIg,
  view_transitions_client_zHs6_XZgPq6gw5kdcHkpxB9ot_4wuPAVFOpKj24zDsQ,
  chunk_reload_client_Xw1G7sNmSLZU_fMeg1F6id0ypmpZP2u_HbpWX79ZAUA,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_6WcLI_RKBNoI5X_pVx1hF9_w_bj_U9JHiRNM_nu3S0Q
]